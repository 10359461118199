import React, { Component } from 'react';
import './Dashboard.scss';
//import ApiDataService from '../../services/ApiDataService';

let timeLeft;
class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {};
    
  }

  // componentWillMount(){}
  
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div>{''}</div>
    );
  }
}

export default Dashboard;