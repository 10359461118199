// FileUpload.js
import React, { useState } from 'react';

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
        } else {
            alert('Please select a PDF file');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await fetch('https://api.github.com/repos/:owner/:repo/contents/:path', {
                method: 'PUT',
                headers: {
                    Authorization: 'Bearer YOUR_GITHUB_TOKEN', // Replace with your GitHub token
                },
                body: JSON.stringify({
                    message: 'Upload PDF file',
                    content: Buffer.from(formData).toString('base64'),
                }),
            });

            if (response.ok) {
                alert('PDF file uploaded successfully!');
            } else {
                const errorMessage = await response.text();
                alert(`Error uploading PDF file: ${errorMessage}`);
            }
        } catch (error) {
            console.error('Error uploading PDF file:', error);
            alert('An error occurred while uploading the PDF file.');
        }
    };

    return (
        <div>
            <input type="file" accept=".pdf" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload PDF File</button>
        </div>
    );
};

export default FileUpload;
